import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "homeView" */ '../views/HomeView.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      hideNav: true,
    },
    component: () => import(/* webpackChunkName: "Login" */ '../views/Login.vue'),
  },
  {
    path: '/training',
    name: 'Training',
    meta: {
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "SetTraining" */ '../views/SetTraining.vue'),
  },
  {
    path: '/startLesson',
    name: 'StartLesson',
    meta: {
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "StartLesson" */ '../views/StartLessonView.vue'),
  },
  {
    path: '/*',
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

// 跳頁前檢查用戶是否已登入, 測試中...
router.beforeEach(async(to, from, next) => {
  const isAuthenticated = store.getters['auth/hasToken'];

  if (!isAuthenticated && to.matched.some(record => record.meta.requiresAuth)) {
    next('/login');
  } else {
    // 有 token 或者不需要資料驗證的頁面，繼續執行 ensureUser
    await store.dispatch('auth/ensureUser');
    next();
  }
});


router.afterEach(async(to) => {
  // console.log(`${JSON.stringify(to.meta)}`);
  let hideNav = false;
  if (to.meta.hideNav) {
    hideNav = true;
  }
  store.commit('SETNAVHIDE', hideNav);
});

export default router
