<template>
  <div id="app" v-if="init">

    <TopNav v-if="!hideNav" class="top-nav"></TopNav>

    <div class="content" :class="{'padding-nav': !hideNav}">
      <router-view/>
    </div>


    <Transition name="fade">
      <div class="loading-mask" v-if="isStoreBusy">
        <b-spinner></b-spinner>
      </div>
    </Transition>
  </div>
</template>

<script>
import TopNav from '@/components/nav/TopNav';
import { mapActions, mapGetters, mapState } from 'vuex';

import moment from 'moment';
moment.locale('zh-tw');

export default {
  name: 'AppView',
  metaInfo() {
    return {
    };
  },  
  data() {
    return {
      init: true,
    };
  },
  async mounted() {
    // try {
    //   await this.$store.dispatch('auth/initLoginToken');
    //   this.init = true;
    // } catch(e) {
    //   console.error(e);
    // }
    window.addEventListener("resize", this.resizeHandler);
    document.dispatchEvent(new Event('render-event'));

  },
  created() {
    window.addEventListener("resize", this.resizeHandler);
    this.resizeHandler();
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeHandler);
  },
  components: {
    TopNav,
  },
  watch: {
    $route(to) {
      if (to.meta.noToTop === undefined || !to.meta.noToTop) {
        window.scrollTo(0,0);
      }
      // console.log(to);
    }
  },
  computed: {
    ...mapGetters(['isStoreBusy', 'auth/hasToken']),
    ...mapState(['hideNav']),
    // routeName() {
    //   return this.$route.name;
    // },
  },
  methods: {
    ...mapActions(['appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg']),
    resizeHandler() {
      this.$store.commit('SETWINDOWWIDTH', window.innerWidth);
    },
  }
}
</script>


<style lang="scss" scoped>

.loading-mask {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #0005;
  opacity: 1;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s ease;
}


</style>

<style>
#app {
  color: #333333;
}

.top-nav {
  position: fixed !important;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
}

.padding-nav {
  padding-top: 60px;
}
</style>