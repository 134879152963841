<template>
  <b-navbar toggleable="lg" type="dark" variant="info">
    <b-navbar-brand href="#">到咖手</b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <!-- <b-navbar-nav>
        <b-nav-item href="#">Link</b-nav-item>
        <b-nav-item href="#" disabled>Disabled</b-nav-item>
      </b-navbar-nav> -->

      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">

        <li class="nav-item">
          <router-link :to="{name:'Home'}" class="nav-link">首頁</router-link>
        </li>

        <li class="nav-item" v-if="userActivated">
          <router-link :to="{name:'Training'}" class="nav-link">安排HGG訓練課程</router-link>
        </li>

        <li class="nav-item" v-if="userActivated">
          <router-link :to="{name:'StartLesson'}" class="nav-link">開始客製化課程</router-link>
        </li>

        <b-button size="sm" class="my-2 my-sm-0" variant="outline-light" @click="logoutClicked">登出</b-button>
        <!-- <b-nav-form>
          <b-form-input size="sm" class="mr-sm-2" placeholder="Search"></b-form-input>
          <b-button size="sm" class="my-2 my-sm-0" type="submit">Search</b-button>
        </b-nav-form> -->

        <!-- <b-nav-item-dropdown text="Lang" right>
          <b-dropdown-item href="#">EN</b-dropdown-item>
          <b-dropdown-item href="#">ES</b-dropdown-item>
          <b-dropdown-item href="#">RU</b-dropdown-item>
          <b-dropdown-item href="#">FA</b-dropdown-item>
        </b-nav-item-dropdown> -->

        <!-- <b-nav-item-dropdown right>
          <template #button-content>
            <em>User</em>
          </template>
          <b-dropdown-item href="#">Profile</b-dropdown-item>
          <b-dropdown-item href="#">Sign Out</b-dropdown-item>
        </b-nav-item-dropdown> -->
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'TopNav',
  data() {
    return {
    };
  },
  props: {

  },
  components: {
  },
  computed: {
    ...mapGetters(['isStoreBusy', 'isMobileSize']),
    ...mapGetters('auth', ['userData']),
    ...mapState(['hideNav']),
    userActivated() {
      return this.userData && this.userData.status === 1;
    },
    userIsOperator() {
      return this.userData && this.userData.isOperator && !this.userData.isAdmin;
    },
    userIsAdmin() {
      return this.userData && this.userData.isAdmin;
    },
  },
  watch: {
    $route() {
      // for (let i=0;i<this.links.length;i++) {
      //   this.links[i].show = false;
      //   this.showMenu = false;
      // }
    },
  },
  methods: {
    logoutClicked() {
      this.$store.dispatch('auth/logout');
      this.$router.push({name: 'Login'});
    }
  }

}
</script>

<style lang="scss" scoped>
</style>