import Vue from 'vue'
import VueCookies from 'vue-cookies';
Vue.use(VueCookies)

const TOKENCOOKIEKEY = 'docaso_mobile';

export default {
  namespaced: true,
  state: {
    TOKENCOOKIEKEY: TOKENCOOKIEKEY,
    token: Vue.$cookies.get(TOKENCOOKIEKEY) || '',
    user: null,
    
    lineLoginClientId: '2006525918',
  },
  getters: {
    hasToken(state) {
      return state.token.length > 0;
    },
    hasCookie(state) {
      return Boolean(Vue.$cookies.get(state.TOKENCOOKIEKEY));
    },
    userToken(state) {
      return state.token;
    },
    userData(state) {
      return state.user;
    },
    lineClientId(state) {
      return state.lineLoginClientId;
    },
  },
  mutations: {
    UPDATETOKEN(state, token) {
      state.token = token;
      Vue.$cookies.set(state.TOKENCOOKIEKEY, token, "30d");
    },
    CLEARTOKEN(state) {
      state.token = '';
      Vue.$cookies.remove(state.TOKENCOOKIEKEY);
      state.user = null;
    },
    UPDATEUSER(state, user) {
      if (user === null) {
        state.user = null;
      } else {
        state.user = Object.assign({}, state.user, user);
      }
    },
  },
  actions: {
    loggedIn(context, token) {
      context.commit('UPDATETOKEN', token);
    },
    loggedOut(context) {
      context.commit('CLEARTOKEN');
    },
    async refreshUser(context) {
      try {
        const user = await context.dispatch('api/getAccountInfoPromise', null, {root:true});
        context.commit("UPDATEUSER", user);
      } catch (e) {
        console.error(e);
      }
    },
    async initLoginToken(context) {
      const savedToken = Vue.$cookies.get(context.state.TOKENCOOKIEKEY);
      // console.log(`get cookie: ${savedToken}`);
      if (savedToken !== null) {
        try {
          const newToken = await context.dispatch('api/postRefreshTokenPromise', savedToken, {root: true});
          await context.dispatch('loggedIn', newToken.token);

          const infoRes = await context.dispatch('api/getAccountInfoPromise', null, {root:true});
          await context.dispatch('updateUser', infoRes);
          
        } catch(e) {
          console.error(e);
          context.dispatch('loggedOut');
        }
      }
    },
    updateUser(context, user) {
      context.commit('UPDATEUSER', user);
    },
    async login({ dispatch, commit }, token) {
      const busyName = 'LOGIN';
      dispatch('appendComponentBusy', busyName, {root:true});
      commit('UPDATETOKEN', token);
      try {
        await dispatch('refreshUser');
      } catch (error) {
        console.error('Login error:', error);
        dispatch('logout');
      } finally {
        dispatch('clearComponentBusy', busyName, {root:true});
      }
    },
    logout(context) {
      context.commit('CLEARTOKEN');
      context.commit("UPDATEUSER", null);
    },
    async refreshToken({ getters, dispatch, commit }, token) {
      // 使用現有token向server索取新token
      if(getters.isAuthenticated) {
        try {
          const response = await dispatch('api/postRefreshToken', token);
          commit('UPDATETOKEN', response.token);
        } catch (error) {
          console.error('Refresh token error:', error);
          dispatch('logout');
        }
      }
    },
    async ensureUser({ state, dispatch }) {
      if (!state.user && !!state.token) {
        // 如果有 token 但沒有使用者資料，先進行 refresh token，再進行 login 請求
        await dispatch('refreshToken', state.token);
        await dispatch('login', state.token);
      }
    },
  }

}
