import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import VueMeta from 'vue-meta';

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import VueMarqueeSlider from 'vue-marquee-slider';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'aos/dist/aos.css';
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueMarqueeSlider);

import '/src/assets/scss/main.scss'

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
});
Vue.config.productionTip = false
Vue.use(require('vue-cookies'));

new Vue({
  router,
  store,
  render: h => h(App),
  mounted() {
  },
}).$mount('#app')
